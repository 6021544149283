import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`19th March 2019`}</p>


    <h2 {...{
      "id": "new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#new-features",
        "aria-label": "new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now include or exclude service methods using `}<inlineCode parentName="p">{`DocServiceFilter`}</inlineCode>{` when building a `}<inlineCode parentName="p">{`DocService`}</inlineCode>{`.
See `}<a parentName="p" {...{
            "href": "https://armeria.dev/docs/server-docservice#including-and-excluding-service-methods"
          }}>{`our documentation`}</a>{` for more information. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1609"
          }}>{`#1609`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = new ServerBuilder();
...
sb.serviceUnder("/docs", new DocServiceBuilder()
        // Include Thrift services and Annotated services.
        .include(DocServiceFilter.ofThrift().or(DocServiceFilter.ofAnnotated()))
        // Exclude the method whose name is "foo" in Thrift services.
        .exclude(DocServiceFilter.ofThrift().and(DocServiceFilter.ofMethodName("foo")))
        .build());
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`HttpHeaderNames`}</inlineCode>{` now forks from Guava's `}<inlineCode parentName="p">{`HttpHeaders`}</inlineCode>{` instead of Netty's `}<inlineCode parentName="p">{`HttpHeaderNames`}</inlineCode>{`
because it has more recent HTTP headers which may be useful to service authors.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can use `}<inlineCode parentName="p">{`SystemInfo`}</inlineCode>{` utility class in order to get the system information. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1656"
          }}>{`#1656`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Java version
SystemInfo.javaVersion();

// Hostname
SystemInfo.hostname();

// The current process ID
SystemInfo.pid();

// The current time in microseconds
SystemInfo.currentTimeMicros();
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#improvements",
        "aria-label": "improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Set 0.5 to the default failure rate threshold of a circuit breaker. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1657"
        }}>{`#1657`}</a></li>
      <li parentName="ul">{`Various documentation updates. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1657"
        }}>{`#1657`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1654"
        }}>{`#1654`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1651"
        }}>{`#1651`}</a></li>
    </ul>
    <h2 {...{
      "id": "bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bug-fixes",
        "aria-label": "bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`Strip a leading slash in an `}<inlineCode parentName="li">{`HttpFile`}</inlineCode>{` resource path. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1661"
        }}>{`#1661`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1650"
        }}>{`#1650`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Will strip the leading slash
HttpFile.ofResource(ClassLoader.getSystemClassLoader(), "/java/lang/Object.class");
`}</code></pre>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`ByteBuf`}</inlineCode>{`s are no longer leaked when you use Spring WebFlux integration. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1658"
        }}>{`#1658`}</a>{`
It was possible to leak a `}<inlineCode parentName="li">{`ByteBuf`}</inlineCode>{` if a client closes a connection before reading the body of an HTTP response.`}</li>
    </ul>
    <h2 {...{
      "id": "breaking-change",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#breaking-change",
        "aria-label": "breaking change permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Breaking Change`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`DocServicePlugin`}</inlineCode>{` interface now has `}<inlineCode parentName="li">{`name()`}</inlineCode>{` method and `}<inlineCode parentName="li">{`generateSpecification()`}</inlineCode>{` method signature has changed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1609"
        }}>{`#1609`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Before:
ServiceSpecification generateSpecification(Set<ServiceConfig> serviceConfigs);
// After:
String name();
ServiceSpecification generateSpecification(Set<ServiceConfig> serviceConfigs, DocServiceFilter filter);
`}</code></pre>
      </li>
      <li parentName="ul">{`Some header names in `}<inlineCode parentName="li">{`HttpHeaderNames`}</inlineCode>{` were removed, although it is very unlikely to be used by users: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1660"
        }}>{`#1660`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`Keep-Alive`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Proxy-Connection`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Content-Transfer-Encoding`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dependencies",
        "aria-label": "dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`java-jwt 3.7.0 -> 3.8.0`}</li>
      <li parentName="ul">{`Guava 27.0.1 -> 27.1`}</li>
      <li parentName="ul">{`Netty 4.1.33 -> 4.1.34`}
        <ul parentName="li">
          <li parentName="ul">{`Netty TCNative BoringSSL 2.0.20 -> 2.0.22`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Brave 5.6.1 -> 5.6.3`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      